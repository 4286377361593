<aside class="aside-bar" id="aside-bar">
    <div class="title">menu</div>
    
    <ul class="nav">
        <ng-container *ngFor="let sidebarnavItem of menuItems">
            <li
                [routerLinkActive]="sidebarnavItem.path ? 'act-route' : ''"
                class="nav-item"
            >
                <a
                    class="flex order-wrapper"
                    [routerLink]="sidebarnavItem.path ? [sidebarnavItem.path] : null"
                    (click)="closeSideMenu()"
                > 
                    <span class="image flex flex-ce items-ce">
                        <img
                            class="nav-icon"
                            alt="Nav Img"
                            [src]="sidebarnavItem.image"
                        >
                        <img
                            class="nav-act-icon"
                            alt="Nav Img"
                            [src]="sidebarnavItem.activeImage"
                        >
                    </span>
                    <div class="item-title">
                        <span>{{sidebarnavItem.title}}</span>
                    </div>
                    <span class="order" *ngIf="sidebarnavItem.title === 'Orders'">
                        {{totalOrder}}
                    </span>
                </a>
                <button
                    *ngIf="sidebarnavItem.submenu.length"
                    type='button'
                    class="btn expand-button"
                    [ngClass]="{expanded: !collapsedMenuIds.has(sidebarnavItem.id)}"
                    (click)="toggleMenu($event, sidebarnavItem)"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="6" viewBox="0 0 12 6" fill="none">
                        <path d="M6 6L0 0H12L6 6Z" fill="#000"/>
                    </svg>
                </button>
            </li>

            <ul
                *ngIf="sidebarnavItem.submenu.length"
                class="submenu nav"
                [ngClass]="{expanded: !collapsedMenuIds.has(sidebarnavItem.id)}"
            >
                <li
                    *ngFor="let sidebarnavSubItem of sidebarnavItem.submenu"
                    [routerLinkActive]="sidebarnavSubItem.path ? 'act-route' : ''"
                    class="submenu-item nav-item"
                >
                    <a
                        class="flex order-wrapper"
                        [routerLink]="sidebarnavSubItem.path ? [sidebarnavSubItem.path] : null"
                        (click)="closeSideMenu()"
                    >
                        <span class="image flex flex-ce items-ce">
                            <img
                                class="nav-icon"
                                alt="Nav Img"
                                [src]="sidebarnavSubItem.image"
                            >
                            <img
                                class="nav-act-icon"
                                alt="Nav Img"
                                [src]="sidebarnavSubItem.activeImage"
                            >
                        </span>
                        <span class="item-title">{{sidebarnavSubItem.title}}</span>
                    </a>
                </li>
            </ul>
        </ng-container>
    </ul>
    <div class="preference">
        <div class="title">Preference</div>
        <ul>
            <!-- <li>
                <a class="flex items-ce" href="javascript:void(0)" routerLink="/settings" (click)="closeSideMenu('')">
                    <span class="image flex flex-ce items-ce">
                        <img src="../../../../../assets/images/aside-icon6.svg" alt="icon6"/>
                    </span> 
                    Settings
                </a>
            </li> -->
            <li routerLinkActive="act-route" class="nav-item">
                <a class="flex items-ce" routerLink="/help-center" (click)="closeSideMenu()">
                    <span class="image flex flex-ce items-ce">
                        <img src="../../../../../assets/images/aside-icon7.svg" alt="icon7" class="nav-icon"/>
                        <img src="../../../../../assets/images/aside-icon7-active.svg" alt="icon7" class="nav-act-icon"/>
                    </span>
                    
                    <span class="item-title"> Help Center</span>
                </a>
            </li>
            <!-- <li routerLinkActive="act-route" class="nav-item">
                <a class="flex items-ce" href="javascript:void(0)" routerLink="/card/card" (click)="closeSideMenu()">
                    <span class="image flex flex-ce items-ce">
                        <img src="../../../../../assets/images/aside-icon7.svg" alt="icon7" class="nav-icon"/>
                        <img src="../../../../../assets/images/aside-icon7-active.svg" alt="icon7" class="nav-act-icon"/>
                    </span>
                    <span class="item-title"> Update Card</span>
                </a>
            </li> -->
        </ul>
    </div>
</aside>
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ConnectionService } from 'ng-connection-service';
import { Subscription } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public noInternetConnection = false;
  private internetConnectionSubscription = Subscription.EMPTY;
  private routerEventsSubscription = Subscription.EMPTY;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public title: Title,
    private connectionService: ConnectionService
  ) {
  }

  ngOnInit(): void {
    this.internetConnectionSubscription = this.connectionService.monitor().subscribe(isConnected => {
      this.noInternetConnection = !isConnected;
    })

    this.routerEventsSubscription = this.router.events
        .pipe(
          filter(event => event instanceof NavigationEnd),
          map(() => {
            let route = this.activatedRoute;
            while (route.firstChild) {
              route = route.firstChild;
            }
            return route.snapshot.data?.title || 'Splurky';
          })
        )
        .subscribe(title => {
          this.title.setTitle(title);
        });
  }

  ngOnDestroy(): void {
    this.internetConnectionSubscription.unsubscribe();
    this.routerEventsSubscription.unsubscribe();
  }
}
